module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demo Cadillac","short_name":"Demo Cadillac","start_url":"/","theme_color":"#13cce8","display":"standalone","background_color":"#ffffff","icon":"src/images/lobster512.png","include_favicon":false,"theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"28ebe20caa40bdf5fb61af241408f5e0"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://cadillac.lobstermarketing.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/pest-library","crumbLabel":"Pest Library"},{"pathname":"/undefined"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../../gatsby-theme-cadillac/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"44de0b1e-2677-0059-99c0-6cae7e474892","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImQ1ZThjNjVkOGRmODQwNjQ5M2RlNjliZTJkMDc4MDE0IiwNCiAgImlhdCI6ICIxNjEyODc5MDQ0IiwNCiAgImV4cCI6ICIxOTU4NDc5MDQ0IiwNCiAgInByb2plY3RfaWQiOiAiNDRkZTBiMWUyNjc3MDA1OTk5YzA2Y2FlN2U0NzQ4OTIiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.b302zAUPFsz79YaP12W4EXdrgy2Puf3oPexIKgEmyrM","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","wWSActive":"0","siteUrl":"https://cadillac.lobstermarketing.com","title":"Demo Cadillac","description":"Demo Cadillac","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","compassID":"42a82a20fd60882a6cf4067db94368738701fce0d5ce61ec6c4845b74d313fbc","recaptchaSiteKey":"6LeQy9ohAAAAAG5XaPoTuz5mYz5PNUbb4jqbqnTF","recaptchaSecretKey":"6LeQy9ohAAAAACVFLq7DZC5j1bxaRmEQCeYVsQ-4","pestLibraryPathName":"pest-library","pestLibraryBreadcrumb":"Pest Library"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
